import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"

const Partners = () => {
  const { strapiHomepage } = useStaticQuery(graphql`
      query {
          strapiHomepage {
              partners {
                  image {
                      childImageSharp {
                          fluid(maxWidth: 1920, quality: 100) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
                  url
                  alt
              }
          }
      }
  `)

  const { partners } = strapiHomepage

  const partnersJsx = partners.map((partner: any, index: number) => {
    const url = partner.image.childImageSharp.fluid.src

    return (
      <div className="gallery-cell" key={index}>
        <div className="partner">
          <a href={partner.url} target="_blank" rel="nofollow noopener">
            <Img fluid={partner.image.childImageSharp.fluid} alt={partner.alt}/>
          </a>
        </div>
      </div>
    )
  })

  return (
    <section className="section padding-top100 wow fadeInUp show-for-large"
             data-wow-duration="1.5s"
             style={{visibility: 'hidden'}}>
      <div className="row partnerzy_row" data-equalizer="partnerzy" data-equalize-on="large">
        <div className="large-4 medium-12 small-12 columns">
          <div className="display-table" data-equalizer-watch="partnerzy">
            <div className="display-table-cell">
              <h2 className="border-bottom large-margin-0">Współpracujemy z:</h2>
            </div>
          </div>
        </div>
        <div className="large-8 medium-12 small-12 columns end">
          <div className="display-table partnerzyframe shadow" data-equalizer-watch="partnerzy">
            <div className="display-table-cell">
              <div className="partnerzy-slider">
                {partnersJsx}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Partners
