import { graphql, useStaticQuery } from "gatsby"
import React from "react"

interface Slide {
  image: any ,
  caption: String,
}

const MainHeader = () => {
  const { strapiHomepage } = useStaticQuery(graphql`
      query {
          strapiHomepage {
              title
              description
              slides {
                  image {
                      childImageSharp {
                          fluid(maxWidth: 1920, quality: 100) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
                  caption
              }
          }
      }
  `)

  const { title, description, slides } = strapiHomepage

  const slidesHtml = slides.map((slide: Slide, index: number) => {
    let caption

    if (slide.caption !== "") {
      caption =
        <div className="photoinfo">
          na zdjęciu:<br />
          {slide.caption}
        </div>
    } else {
      caption = ""
    }

    const url = slide.image.childImageSharp.fluid.src

    return (
      <div className="gallery-cell" key={index}>
        <div className={"photobg rightbg height100vh"} style={{ backgroundImage: `url(${url})` }}>
          {caption}
        </div>
      </div>
    )
  })

  return (
    <header id="mainheader" className="height100vh">
      <div className="scrollarrow">
        <i className="fa fa-angle-down" aria-hidden="true"></i>
      </div>
      <div className="header-inner height100vh">
        <div className="display-table">
          <div className="display-table-cell">
            <div className="row wow fadeInUp" data-wow-duration="1.5s" style={{ visibility: "hidden" }}>
              <div
                className="large-6 large-centered medium-8 medium-centered small-10 small-centered columns text-center">
                <h1 className="white border-bottom border-center">
                  {title}
                </h1>
                <div className="font-mobile">
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-slider">
        {slidesHtml}
      </div>
    </header>
  )
}

export default MainHeader
