import {graphql, Link, useStaticQuery, withPrefix} from "gatsby"
import React from "react"
import BackgroundImage from 'gatsby-background-image'

const Team = () => {
  const {strapiHomepage} = useStaticQuery(graphql`
      query {
          strapiHomepage {
              team {
                  image {
                      childImageSharp {
                          fluid(maxWidth: 1920, quality: 100) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
          }
      }
  `)

  const {team} = strapiHomepage

  const teamForLargeScreensJsx = team.map((member: any, index: number) => {
    return (
      <div className="columns" key={index}>
        <BackgroundImage
          fluid={member.image.childImageSharp.fluid}
          className="kadra photobg">
        <Link to="/o-nas"></Link>
        </BackgroundImage>
      </div>
    )
  })

  const teamForSmallScreensJsx = team.map((member: any, index: number) => {
  const url = member.image.childImageSharp.fluid.src

  return (
  <div className="gallery-cell" key={index}>
  <div className="kadra photobg"
  style={{ backgroundImage: `url(${url})` }}>
  <Link to="/o-nas"></Link>
  </div>
  </div>
  )
  })

  return (
  <section className="section padding-top100 wow fadeInUp" data-wow-duration="1.5s" style={{ visibility: "hidden" }}>
  <div className="row" data-equalizer="kadra" data-equalize-on="large">
  <div className="large-7 large-push-5 medium-12 medium-push-12 small-12 small-push-12 columns">
  <div className="display-table" data-equalizer-watch="kadra">
  <div className="display-table-cell">
  <h2 className="border-bottom">Nasz zespół</h2>
  <div className="font-mobile">
  Na naszą urodę wpływa wiele czynników, które są elementami różnych specjalności. Mamy tego świadomość,
  dlatego tworzymy nasz zespół w oparciu o wiedzę i doświadczenie specjalistów różnych dziedzin.
  </div>
  <p className="text-left show-for-large">
  <Link to="/o-nas" className="arrow-link">dowiedz się więcej o nas</Link>
  </p>
  </div>
  </div>
  </div>
  <div className="large-5 large-pull-7 medium-12 medium-pull-12 small-12 small-pull-12 columns">
  <div className="display-table" data-equalizer-watch="kadra">
  <div className="display-table-cell">
  <div className="row small-up-3 medium-up-3 large-up-4 show-for-large">
  {teamForLargeScreensJsx}
  </div>

  <div className="kadra-slider hide-for-large">
  {teamForSmallScreensJsx}
  </div>
  </div>
  </div>
  </div>
  </div>
  </section>
  )
  }

  export default Team
