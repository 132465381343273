import { withPrefix } from "gatsby"
import React from "react"

const Recommended = () => {
  return (
    <section className="section padding-top60 wow fadeInUp" data-wow-duration="1.5s" style={{visibility: 'hidden'}}>
      <div className="row" data-equalizer="video" data-equalize-on="large">
        <div className="large-5 medium-12 small-12 columns">
          <div className="display-table" data-equalizer-watch="video">
            <div className="display-table-cell">
              <h2 className="border-bottom">Poczuj się wyjątkowo</h2>
              <div className="font-mobile">
                Otoczeni nowoczesnym sprzętem, w estetycznych wnętrzach kliniki, specjaliści różnych dziedzin łączą swoje kompetencje dbając o zdrowie i urodę naszych pacjentów.
              </div>
            </div>
          </div>
        </div>
        <div className="large-7 medium-12 small-12 columns">
          <div className="display-table" data-equalizer-watch="video">
            <div className="display-table-cell">
              <div className="row collapse" data-equalizer="video-ramka" data-equalize-on="medium">
                <div className="small-12 columns show-for-large">
                  <div className="display-table" data-equalizer-watch="video-ramka">
                    <div className="display-table-cell">
                      <div
                        className="video-ramka photobg"
                        style={{backgroundImage: `url(${withPrefix("images/homepage/yt-thumbnail.jpg")})`}}>
                        <a href="#" data-popup="#popup-video" className="popupopen all"></a>
                        <a href="#" data-popup="#popup-video" className="popupopen play shadow">
                          <i className="fa fa-play" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="small-12 columns hide-for-large">
                  <div className="display-table" data-equalizer-watch="video-ramka">
                    <div className="display-table-cell">
                      <div
                        className="video-ramka photobg"
                        style={{backgroundImage: `url(${withPrefix("images/homepage/yt-thumbnail.jpg")})`}}>
                        <a href="#" data-popup="#front_1" className="popupopen all"></a>
                        <a href="#" data-popup="#front_1" className="popupopen play shadow">
                          <i className="fa fa-play" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Recommended
