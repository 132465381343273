import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"

import BackgroundImage from "gatsby-background-image"

interface Offer {
  title: string,
  linkToAvailableProcedures: string,
  linkToPrice: string
}

const Offer = () => {
  const { strapiHomepage } = useStaticQuery(graphql`
      query {
          strapiHomepage {
              offers {
                  title
                  linkToAvailableProcedures
                  linkToPrice
              }
          }
      }
  `)

  let { offers } = strapiHomepage

  const offersFirst = offers.map((offer: Offer, index: number) => {
    return (
      <div className="gallery-cell" key={index}>
        <h6 className="normal oferta-head">{offer.title}</h6>
        <ul className="category-menu">
          <li>
            <Link to={offer.linkToAvailableProcedures}>
              dostępne zabiegi
            </Link>
          </li>
          <li>
            <Link to={offer.linkToPrice}>
              cennik
            </Link>
          </li>
        </ul>
      </div>
    )
  })

  const offersDesktop = offers.map((offer: Offer, index: number) => {
    return (
      <div className="column" key={index}>
        <h4 className="kreska">0{index + 1}</h4>
        <h6 className="normal border-bottom-black oferta-head">{offer.title}</h6>
        <ul className="category-menu">
          <li>
            <Link to={offer.linkToAvailableProcedures}>
              dostępne zabiegi
            </Link>
          </li>
          <li>
            <Link to={offer.linkToPrice}>
              cennik
            </Link>
          </li>
        </ul>
      </div>
    )
  })

  return (
    <section id="first" className="section padding-top100 wow fadeInUp" data-wow-duration="1.5s"
             style={{ visibility: "hidden" }}>
      <div className="row">
        <div className="large-12 large-centered medium-12 small-12 columns">
          <div className="category-slider-numbers hide-for-large">
            <h4>
              <span id="current-number" className="kreska">01</span>
              <span className="number"></span>
            </h4>
          </div>
          <div className="category-slider hide-for-large">
            {offersFirst}
          </div>

          <div className="row small-up-1 medium-up-3 large-up-5 show-for-large flex-center">
            {offersDesktop}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Offer
