import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"

interface OfferBox {
  link: string,
  colour: string,
  promo: boolean
  promoValue: string
  title: string
  description: string
}

const OfferBoxes = () => {
  const { strapiHomepage } = useStaticQuery(graphql`
      query {
          strapiHomepage {
              offerBoxes {
                  link
                  colour
                  promo
                  title
                  description
              }
          }
      }
  `)

  let { offerBoxes } = strapiHomepage

  const offerBoxesJsx = offerBoxes.map((offer: OfferBox, index: number) => {
    return (
      <div className="large-3 medium-4 small-12 columns offer-boxes-item" key={index}>
        <Link to={offer.link}>
          <div className="inner" style={{ backgroundColor: offer.colour }}>
            {offer.promo &&
            <div className="promo" style={{backgroundColor: '#78cb7f'}}>
              {offer.promoValue}
            </div>
            }
            <h2>{offer.title}</h2>
            <p>
              {offer.description}
            </p>
          </div>
        </Link>
      </div>
    )
  })

  return (
    <section className="section offer-boxes wow fadeInUp" data-wow-duration="1.5s" style={{visibility: 'hidden'}}>
      <div className="row">
        {offerBoxesJsx}
      </div>
    </section>
  )
}

export default OfferBoxes;
