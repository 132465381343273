import React from "react"

import MainHeader from "../components/homepage/mainheader"
import Offer from "../components/homepage/offer"
import OfferBoxes from "../components/homepage/offer-boxes"
import AnsweringQuestions from "../components/homepage/answering-questions"
import Shortcut from "../components/homepage/shortcut"
import Recommended from "../components/homepage/recommended"
import Team from "../components/homepage/team"
import Partners from "../components/homepage/partners"
import TableOfContents from "../components/homepage/table-of-contents"

import Layout from "../components/layout"
import Header from "../components/header"
import {graphql} from "gatsby";

const IndexPage = () => {
  return (
    <Layout>
      <Header></Header>
      <MainHeader />
      <Offer />
      <OfferBoxes />
      <AnsweringQuestions/>
      <Shortcut />
      <Recommended />
      <Team />
      <Partners />
      <div className="popup-container" id="popup-video" data-ytid='7TAza4L7WXY'>
        <div className="popup-element">
          <span className="popup-close" data-popup="#popup-video">×</span>
          <iframe frameBorder="0" allowFullScreen></iframe>
        </div>
      </div>
      <TableOfContents />
    </Layout>
  )
}

export default IndexPage
