import {graphql, useStaticQuery, withPrefix} from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import React from "react"

const AnsweringQuestions = () => {
  const {bgAnsweringQuestions} = useStaticQuery(graphql`
      query {
          bgAnsweringQuestions: file(relativePath: { eq: "images/question-bg.jpg" }) {
              childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid(maxWidth: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)
  return (
    <BackgroundImage
      fluid={bgAnsweringQuestions.childImageSharp.fluid}
      Tag={'section'}
      className="section answering-questions wow fadeInUp"
      data-wow-duration="1.5s"
      style={{visibility: 'hidden'}}
    >
      <div className="row">
        <div className="large-5 medium-8 small-12 columns">
          <div className="inner">
            <img src={withPrefix("images/dent-logo.png")} />
            <h2>
              Odpowiadamy na pytania
            </h2>
            <p>
              Dowiedz się jak pracujemy
              co robimy.
            </p>
            <a href="#" data-popup="#popup-video" className="popupopen play shadow">
              <i className="fa fa-play" aria-hidden="true"></i>
              <span>Zobacz</span>
            </a>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default AnsweringQuestions
